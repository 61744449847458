.Solution {
  width: 280px;

  > div {
    margin-bottom: 10px;

    &:hover {
      color: #428dff;
    }
  }
}

.Service {
  display: flex;
  gap: 40px;
  width: 380px;
  > div {
    .serviceItemHead {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
      span {
        &:hover {
          color: #428dff;
        }
      }
    }
    > .serviceItemList {
      > li {
        margin-top: 10px;
        &:hover {
          color: #428dff;
        }
      }
    }
  }
}
.Resources {
  width: 540px;
  display: flex;
  gap: 10px;

  > div:nth-of-type(1) {
    flex: 2.5;
    > div {
      margin-bottom: 20px;
      &:hover {
        color: #428dff;
      }
    }
  }
  > div:nth-of-type(2) {
    flex: 3;
    > div:nth-of-type(1) {
    }
    > div {
      margin-bottom: 10px;
      &:hover {
        color: #428dff;
      }
    }
  }
  > div:nth-of-type(3) {
    flex: 4.5;

    > div {
      margin-bottom: 10px;
      &:hover {
        color: #428dff;
      }
    }
    > div:nth-of-type(1) {
    }
    > div:not(:nth-of-type(1)) {
      display: flex;
      > div:nth-of-type(1) {
        margin-right: 10px;
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
      > div:nth-of-type(2) {
        > div:nth-of-type(2) {
          font-size: 14px;
          color: rgba(2, 24, 58, 0.6);
          &:hover {
            color: #428dff;
          }
        }
      }
    }
  }
}

