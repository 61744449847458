.mainContainer {
  cursor: pointer;

  .content {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-left: 5px;
      color: var(--app-text-color);
    }
  }
}

