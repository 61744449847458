.headerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .unLogin {
    background: #725cff;
    text-align: center;
    height: 45px;
    line-height: 45px;
    color: var(--foreground-rgb);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    & > span:nth-child(1) {
      margin-right: 5px;
      // text-decoration: underline;
      cursor: pointer;
      border-bottom: 1px solid var(--foreground-rgb);
    }
  }
  .globalHeaderWarp {
    // height: 60px;
    height: var(--pietra-global-header-heigth);
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    // background: var(--foreground-rgb);
    padding: 0 2rem;
    transition: padding 0.2s ease;

    &.shopPage {
      padding-left: 25px;
    }

    .navSection {
      gap: 0rem;
      display: flex;
      align-items: center;
      color: var(--app-text-color);
      .avatar {
        display: inline-block;
        height: 2rem;
        width: 2rem;
        text-align: center;
        line-height: 2rem;
        background-color: var(--app-primary-color);
        border-radius: 2rem;
        margin-left: 1rem;
        cursor: pointer;
        color: #ffffff;
      }
      > .login {
        height: 2.25rem;
        border-radius: 1.125rem;
        padding: 0 1.5rem;
        background: linear-gradient(270deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%), #02183a;
        color: var(--foreground-rgb);
        // background-color: var(--app-primary-color);
        font-size: 0.875rem;
        border: none;
      }

      &:last-child {
        justify-content: flex-end;
      }

      > .navSearch {
        display: flex;
        align-items: center;
        width: 100%;

        .sparkStone {
          cursor: pointer;
          width: 50px;
          height: 50px;
          object-fit: contain;
          border-radius: 0;
          background: none;
          margin-right: 1rem;
        }
      }

      > .cartIcon {
        cursor: pointer;
        margin-left: 20px;
        transform: translateY(2px);
      }
    }

    .navTitleContainer {
      cursor: pointer;

      .catchPhrase {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        white-space: nowrap;

        .retroTitle {
          font-size: 26px;
        }

        .logo {
          display: inline-block;
          width: 32px;
          height: 32px;
        }

        .name {
          display: inline-block;
          height: 32px;
          width: auto;
        }
        .cc {
          display: inline-block;
          width: 32px;
          height: 32px;
          border-radius: 32px;
          background: linear-gradient(180deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%);
        }
        .webname {
          font-weight: 900;
          font-size: 20px;
          color: #02183a;
          vertical-align: super;
          margin-left: 10px;
        }

        > .poweredBy {
          margin-top: 3px;
          font-size: 12px;

          > .logo {
            margin-left: 10px;

            > .pietra {
              width: 40px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
.menus {
}
.p_con {
  :global {
    // .next-antd-upload.next-antd-upload-select {
    //   border: 1px solid #2b2b2b !important;
    // }
  }
  padding: 2rem 1rem 0rem;
  color: #000000;
  > div:nth-of-type(1) {
  }
  > div:nth-of-type(2) {
    height: 1px;
    width: 100%;
    background-color: #2d2d2d;
    margin: 1rem 0;
  }

  .btn_c {
    display: flex;
    gap: 0.5rem;
    display: flex;
    gap: 0.5rem;
    margin-top: 1.5rem;
    button {
      width: 50%;
      font-size: 0.8rem;
      height: 2.3rem;
    }
    > button:nth-of-type(1) {
      color: #02183a;
    }
    > button:nth-of-type(2) {
      background-color: #02183a;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 576px) {
  .globalHeaderWarp {
    .navSection {
      > .login {
        padding: 0;
        min-width: 2.25rem;
      }
    }
  }
}

